import React from "react"
import PreorderForm from "../components/organisms/PreorderForm"
import CookieBar from "../components/organisms/CookieBar"

const Preorder = () => {
  return (
    <>
      <div>
        <PreorderForm />
      </div>
      <CookieBar />
    </>
  )
}

export default Preorder
