import React, { useState, useRef } from "react"
import TextareaAutosize from "react-textarea-autosize"
import P from "../../atoms/P"
import Button from "../../atoms/Button"
import HeaderText from "../../atoms/HeaderText"
import styled from "styled-components"

const X = styled.div`
  position: absolute;
  top: 3%;
  right: 3%;
  background: url("/x-icon.svg");
  background-size: cover;
  width: 30px;
  height: 30px;
  cursor: pointer;
`
const Container = styled.div`
  position: relative;
  background: white;
  padding: 20px;
  width: 100%;
  input:not([type="radio"]):not([type="checkbox"]) {
    -webkit-appearance: none;
    border-radius: 0;
  }
  textarea {
    -webkit-appearance: none;
    border-radius: 0;
  }
  border-top: 20px solid ${props => props.theme.colors.site.primaryLight};
  input {
    border: 0px;
    border-bottom: 1px solid ${props => props.theme.colors.site.primaryDark};
    &::placeholder {
      font-size: 14px;
      color: ${props => props.theme.colors.disabled};
    }
  }
  //mobile
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    padding: 96px 142px 76px 142px;
  }
`
const FirstRow = styled.div`
  display: flex;

  flex-direction: column;
  input {
    flex-grow: 1;
    margin-top: 24px;
    &:last-of-type {
      margin-left: 0px;
    }
  }
  //mobile
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    flex-direction: row;
    margin-bottom: 36px;

    input {
      flex-grow: 1;
      &:last-of-type {
        margin-left: 32px;
      }
    }
  }
`
const SecondRow = styled.div`
  display: flex;
  flex-direction: column;
  input {
    &:first-of-type {
      margin-top: 24px;
      margin-bottom: 24px;
      width: 100%;
    }
  }

  //mobile
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    flex-direction: row;
    margin-bottom: 36px;

    input {
      &:first-of-type {
        width: calc(50% - 16px);
        margin-bottom: 0;
      }
    }
  }
`
const SecondRowCheckboxRow = styled.div`
  flex-direction: column;

  //mobile
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    margin-left: 36px;
  }
`
const ThirdRow = styled.div`
  display: flex;

  flex-direction: column;
  input {
    flex-grow: 1;
    &:first-of-type {
      margin-top: 24px;
    }
    &:last-of-type {
      margin-top: 24px;
      margin-left: 0px;
    }
  }
  //mobile
  @media only screen and (min-width: ${props =>
      props.theme.screenBreakpoints.tabletMin}px) {
    flex-direction: row;
    margin-bottom: 60px;

    input {
      flex-grow: 1;
      &:last-of-type {
        margin-left: 32px;
      }
    }
  }
`
const TextWrapper = styled.div``
const Textarea = styled.div`
  * {
    border: none;
    border-bottom: 1px solid ${props => props.theme.colors.site.primaryDark};
    resize: none;
    display: block;

    &::placeholder {
      font-size: 14px;
      color: ${props => props.theme.colors.disabled};
    }
  }

  &:not(:last-child) {
    margin-top: 24px;
    margin-bottom: 24px;

    @media only screen and (min-width: ${props =>
        props.theme.screenBreakpoints.tabletMin}px) {
      margin-top: 0;
      margin-bottom: 60px;
    }
  }
`
const SecondRowCheckboxItem = styled.div`
  display: flex;
  justify-content: center;

  input {
    width: 15px;
    background-image: url("/checkbox.svg");
    background-size: 20%;
    height: 15px;
  }

  input[type="checkbox"] + label {
    background: url("/checkbox.svg") no-repeat;
    background-size: 100%;
    position: absolute;
    height: 100%;
    width: 100%;
    display: inline-block;
  }
  input[type="checkbox"]:checked + label {
    background: url("/checkmarks.svg") no-repeat;
    background-position-x: 4px;
    background-position-y: 2.5px;
    background-size: 60%;
    display: inline-block;
  }
  #checkbox1 {
    display: none;
    &:checked {
      background-image: url("/checkmarks.svg");
    }
  }
  #checkbox2 {
    display: none;
    &:checked {
      background-image: url("/checkmarks.svg");
    }
  }
  p {
    margin-top: -4px;
    margin-left: 10px;
    margin-right: 40px;
    margin-bottom: 0;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`
const Bg = styled.div`
  background: url("/checkbox.svg") no-repeat;
  height: 20px;
  width: 20px;
  background-size: cover;
  position: relative;
`

const PreorderForm = ({ closeMethod }) => {
  const [contactForm, setContactForm] = useState("") // eslint-disable-line
  const contactFormPhoneRef = useRef()
  const contactFormEmailRef = useRef()
  return (
    <Container>
      {" "}
      <X onClick={closeMethod} />
      <form
        action="/enterprise/?success=true"
        name="Pre-order Form"
        method="POST"
        data-netlify="true"
      >
        <input type="hidden" name="form-name" value="Pre-order Form" />

        <TextWrapper>
          <HeaderText color="text">Pre-Order</HeaderText>
          <P size={6}>
            Thank you for your interest in purchasing Norbert products! A member
            of our staff will be in contact shortly to gather additional details
            and complete your order.
          </P>
        </TextWrapper>

        <div>
          <FirstRow>
            <input
              type="text"
              name="name"
              placeholder="Full name*"
              required={true}
            />
            <input
              type="text"
              name="email"
              placeholder="Email address*"
              required={true}
            />
          </FirstRow>
          <SecondRow>
            <input
              type="tel"
              name="phone"
              placeholder="Phone number*"
              required={true}
            />
            <SecondRowCheckboxRow>
              <P size="small" color="disabled">
                Preferred mode of contact:
              </P>
              <div style={{ display: "flex", marginTop: "-10px" }}>
                <SecondRowCheckboxItem>
                  <Bg>
                    <input
                      type="checkbox"
                      name="contactform_phone"
                      id="checkbox1"
                      onChange={e => {
                        setContactForm(e.target.value)
                        if (contactFormEmailRef.current.checked)
                          contactFormEmailRef.current.checked =
                            !contactFormEmailRef.current.checked
                      }}
                      value="Phone"
                      ref={contactFormPhoneRef}
                    />
                    {/* eslint-disable jsx-a11y/label-has-associated-control */}
                    <label htmlFor="checkbox1"></label>
                    {/* eslint-enable */}
                  </Bg>
                  <P size="small" color="text">
                    Phone
                  </P>
                </SecondRowCheckboxItem>
                <SecondRowCheckboxItem>
                  <Bg>
                    <input
                      type="checkbox"
                      id="checkbox2"
                      onChange={e => {
                        setContactForm(e.target.value)
                        if (contactFormPhoneRef.current.checked)
                          contactFormPhoneRef.current.checked =
                            !contactFormPhoneRef.current.checked
                      }}
                      ref={contactFormEmailRef}
                      name="contactform_phone"
                      value="Email"
                    />
                    {/* eslint-disable jsx-a11y/label-has-associated-control */}
                    <label htmlFor="checkbox2"></label>
                    {/* eslint-enable */}
                  </Bg>

                  <P size="small" color="text">
                    Email
                  </P>
                </SecondRowCheckboxItem>
              </div>
            </SecondRowCheckboxRow>
          </SecondRow>
          <ThirdRow>
            <input
              type="text"
              name="industry"
              placeholder="Industry*"
              required={true}
            />
            <input
              type="text"
              name="company_size"
              placeholder="Company Size*"
              required={true}
            />
          </ThirdRow>
          <Textarea>
            <TextareaAutosize
              style={{ width: "100%" }}
              placeholder="What are you interested in using Norbert for?*"
              type="textarea"
              name="What_are_interested"
              minRows={3}
              required={true}
            />
          </Textarea>

          <Textarea>
            <TextareaAutosize
              style={{ width: "100%" }}
              placeholder="How many devices are you interested in?*"
              type="textarea"
              name="How_many_devices"
              minRows={3}
              required={true}
            />
          </Textarea>
        </div>
        <ButtonContainer>
          <Button color="tertiary" type="submit">
            SUBMIT
          </Button>
        </ButtonContainer>
      </form>
    </Container>
  )
}

export default PreorderForm
